<template>
  <div class="main-box">
    <!--el-form class="form-inline" :inline="true">
      <el-form-item label="名称">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form-->
    <el-table
            :data="tableData"
            ref="rfTable"
            border
            style="width: 100%"
            size="mini">
      <el-table-column
              prop="courseid"
              label="ID"
              align="center"
              width="100">
      </el-table-column>
      <el-table-column
              prop="course_name"
              label="课程名称">
      </el-table-column>
      <el-table-column
              prop="price"
              label="价格"
              align="center"
              width="120">
      </el-table-column>
      <el-table-column
              prop="people_number"
              label="人数"
              align="center"
              width="160">
      </el-table-column>
      <el-table-column
              prop="course_time"
              label="课时"
              align="center"
              width="120">
      </el-table-column>
      <el-table-column
              prop="ftime"
              label="状态"
              width="80">
        <template slot-scope="scope">
          <el-switch :value="scope.row.status == 2 ? true : false" @change="changeStatus(scope.row.courseid, scope.row.status)" v-if="scope.row.status"></el-switch>
        </template>
      </el-table-column>
      <el-table-column
              prop="create_time"
              label="创建时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit
        // keywords: that.search.keywords
      }
      this.$api.course.coursePackageIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    changeStatus(courseId, status) {
      this.$api.course.coursePackageShenhe({course_id: courseId, status: status == 1 ? 2 : 1}, res => {
        if(res.errcode == 0) {
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    editGroup(item) {
      this.$router.push({name: 'course_package_edit', query: {course_id: item.courseid}})
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
